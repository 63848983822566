import React from "react"
import Layout from "../components/Layout"
import { Box, Text } from "theme-ui"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import ContentSection from "../components/ContentSection"
import PageHeader from "../components/PageHeader"

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`

const AboutPage = ({ data }) => (
  <Box>
    <SEO title="About" />
    <Layout>
      <PageHeader>
        <Text
          sx={{ fontSize: [3, 5], fontWeight: "bold", textAlign: "center" }}
        >
          About Us
        </Text>
      </PageHeader>
      <ContentSection
        header="Overview"
        body={
          <Box
            sx={{
              maxWidth: "800px",
              marginTop: ["-50px", "auto"],
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "auto",
              textAlign: "justify",
            }}
          >
            <Text variant="secondary" sx={{ fontSize: [0, 3] }}>
              Our systematic teaching methods are well developed technically
              with detailed notes to students to understand quickly. Our faculty
              encourages the students to develop periodically with a positive
              attitude to clear <b> UPSC exam.</b>
            </Text>

            <Text variant="secondary" sx={{ fontSize: [0, 3] }} mt={4}>
              <b>
                We provide Online /Live and offline class in English and Tamil
                language..
              </b>{" "}
              Courses are well designed for daily, weekend, and postal coaching
              to participate anywhere in India for{" "}<b>every UPSC Students</b> .
              Now your dreams will come true for you to achieve your goal as {" "}
              <b>Civil Servant in India.</b>
            </Text>

            <Text variant="secondary" sx={{ fontSize: [0, 3] }} mt={4}>
              Using innovative analogies to simplify the concepts and with
              simple & lucid explanation in day to day language.
            </Text>

            <Text variant="secondary" sx={{ fontSize: [0, 3] }} mt={4}>
              Our Academy gives Quality education services for{" "}
              <b> UPSC Civil Service Exam</b> preparation including General
              study preparation and Public administration preparation services
              <b> with India's highly experienced Faculties and Scholars</b>.
            </Text>

            <Text variant="secondary" sx={{ fontSize: [0, 3] }} mt={4}>
              <b>
                {" "}
                Highly Qualified Faculty is as our primary pillar to provide
                UPSC Classes{" "}
              </b>{" "}
              for the preparation Courses and help students to gain
              authentically and properly basic conceptual clarity to lead{" "}
              <b>UPSC examination</b> with the quality of Knowledge.
            </Text>

            <Text variant="secondary" sx={{ fontSize: [0, 3] }} mt={4}>
              All the necessary notes you need are already in the printed form,
              which will be revised and updated after every batch. So, you can
              use your classroom sessions to develop a mastery on the concepts.
            </Text>

            <Text variant="secondary" sx={{ fontSize: [0, 3] }} mt={4}>
              For your dream career, with the help of our highly qualified
              mentorship, we are constantly working hard to provide unique
              learning experiences with the passion of crossing experiences, A
              pride in having certain contribution to nation building albeit, in
              a small way.
            </Text>

            <Text variant="secondary" sx={{ fontSize: [0, 3] }} mt={4}>
              Our team awaits here to guide you step by step towards overall
              excellence which will automatically lead to success. You simply
              cannot get such teaching excellence anywhere else{" "}
              <b>OTHER THAN SARKAR IAS ACADEMY.....</b>
            </Text>
          </Box>
        }
      />
      <Box id="vision" mt={4} py={3} bg="#F5F5F5">
        <ContentSection
          header="Vision"
          body={
            <Box
              sx={{
                maxWidth: "800px",
                marginTop: ["-50px", "auto"],
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "auto",
                textAlign: "justify",
              }}
            >
              <Text variant="secondary" sx={{ fontSize: [0, 3] }}>
                <b>
                  {" "}
                  To be Proud and taking social responsibility in putting
                  together everyone as one of our best creation as qualified
                  Civil Servant.
                </b>
              </Text>
              <Text variant="secondary" mt={4} sx={{ fontSize: [0, 3] }}>
                <b>Our Academy</b> make the various aspirants from
                socio-economic backgrounds realize
                <b> and fullful their dream of becoming civil servants.</b>
              </Text>
              <Text variant="secondary" mt={4} sx={{ fontSize: [0, 3] }}>
                Playing a primary role in improving & learning ability of
                students and leading them to success with proper guidance and
                creating a learning avenues with a new approach for individual
                care of every student with our study material, makes the
                aspirants feel happy and encouraging .
              </Text>
              <Text variant="secondary" mt={4} sx={{ fontSize: [0, 3] }}>
                <b>
                  Becoming a renowned institution producing eminent and
                  responsible civil servants for the society made us feel very
                  happy.
                </b>
              </Text>
            </Box>
          }
        />
      </Box>
      <Box id="mission" py={3}>
        <ContentSection
          header="Mission"
          body={
            <Box
              sx={{
                maxWidth: "800px",
                marginTop: ["-50px", "auto"],
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "auto",
                textAlign: "justify",
              }}
            >
              <Text variant="secondary" sx={{ fontSize: [0, 3] }}>
                <b>
                  Our mission is to establish an educational ecosystem offering
                  technical support to students worldwide.
                </b>{" "}
                We aim to equip civil services exam candidates with effective,
                efficient, enthusiastic preparation through top-notch coaching
                and superior study materials.<b> We are committed to delivering
                meticulous and targeted training to ensure aspirants success in
                the civil services examination.</b>
              </Text>
            </Box>
          }
        />
      </Box>
    </Layout>
  </Box>
)

export default AboutPage
